html {
  scroll-behavior: smooth;
}
.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.Start-button {
  background-color: white;
  border: 2px solid #1c7e7e;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: #1c7e7e;
  font-size: large;
  font-weight: 600;
  font-family: system-ui;
  cursor: pointer;
  width: 10rem;
  line-height: 1.5;
  margin: 1rem;
}
.Start-button:hover {
  background-color: #1c7e7e;
  color: white;
}

.Progress-bar {
  width: 1;
  height: 20px;
  background-color: teal;
  border-radius: 15px;
  transition: width 3s;
}
.Outer-bar {
  background-color: lightgrey;
  border-radius: 15px;
  display: none;
}
.Progress-msg {
  font-size: medium;
}

.Summary-card {
  padding: 0.5rem;
  font-size: medium;
  color: teal;
  border-radius: 5px;
  text-align: left;
  box-shadow: 1px 1px 1px 1px palevioletred;
  transition: opacity 3s ease-out;
  margin-top: 1rem;
}
.Summary-container {
  width: 70%;
  margin: auto;
  max-height: 400px;
  overflow-y: auto;
  padding: 0.25rem;
}

.Header-div {
  position: fixed;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}

.Logo-image {
  width: 60px;
  border-radius: 2rem;
}
.Header-title {
  font-size: medium;
  color: teal;
  margin-left: 0.25rem;
  background-color: white;
}

.Footer-div {
  position: fixed;
  bottom: 10px;
  max-width: 100%;
  width: 900px;
  text-align: center;
  align-content: center;
  flex-direction: column;
  background-color: white;
}
.Footer-img {
  width: 100px;
  display: block;
  margin: auto;
}
.Footer-text {
  font-size: medium;
}
.Final-msg {
  padding: 0.5rem;
  border-radius: 15px;
  font-size: medium;
  margin: auto;
  background-color: teal;
  color: white;
  font-weight: 500;
}
.Progress-container {
  width: 80%;
  margin: auto;
}
.Main-display {
  position: fixed;
  top: 30%;
  width: 90%;
}

.Instructions-div {
  font-size: 1rem;
  text-align: left;
  margin: 2rem;
}

.First-div {
  border: 2px solid teal;
  border-radius: 10px;
  box-shadow: 5px 5px 5px lightblue;
}

.Caution-yellow {
  color: red;
  font-weight: 400;
  margin-right: 1rem;
}
.Final-icon {
  font-size: 9vh;
  color: #ff5a5a;
}

@media only screen and (max-width: 600px) {
  .First-div {
    box-shadow: 2px 2px 2px lightslategrey;
  }
}

@media only screen and (min-width: 600px) {
  .Final-msg {
    width: 70%;
  }
  .Summary-container {
    width: 60%;
  }
}
